.custom_popover .ant-popover-content .ant-popover-inner{
    background-color: #F6EFEE !important;
    border-radius: 0.4vw;
    
    
}

.custom_popover .ant-popover-content .ant-popover-arrow-content{
    --antd-arrow-background-color: #F6EFEE;
    
}