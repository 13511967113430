.myimage {
	border-right: 16vw solid #f9fafb;
	border-top: 17vw solid transparent;
	border-bottom: 17vw solid transparent;
    position: absolute;
    right: -5.5vw;
    top:4vw;
}

.myimage2 {
	border-right: 16vw solid #f9fafb;
	border-top: 15vw solid transparent;
	border-bottom: 15vw solid transparent;
    position: absolute;
    right: -5.5vw;
    top:3vw;
}

.icons{
    position: absolute;
    left: .85vw;
    top:10vw;
}

.icons2{
    position: absolute;
    left: .85vw;
    top:11vw;
}