.training_section {
  display: flex;
  align-items: center;
  padding: 15px 0;
  border-bottom: 1px solid #eaeaea;
  margin-top: -20px;
}
.training_section > img {
  margin-right: 15px;
}
.training_section > div:first-of-type {
  color: #7a7a7a;
  font-size: 18px;
}
.training_percentage {
  color: #11256d;
  font-weight: 500;
  font-size: 18px;
  margin-left: auto;
}
/* Data Section */
.data_section {
  display: flex;
  align-items: center;
  padding: 20px 0 16px 0;
  border-bottom: 1px solid #eaeaea;
}
.data_container {
  display: flex;
  flex-direction: column;
  width: 25%;
}
.data_heading {
  font-size: 15px;
  color: #c4c4c4;
}
.data_value {
  font-weight: 500;
  font-size: 18px;
}
/* Data Section */
/* Graph Section */
.graph_section {
  display: flex;
  flex-direction: column;
  padding-top: 15px;
  border-bottom: 1px solid #eaeaea;
}
.detailed_report_btn {
  display: flex;
  justify-content: center;
  align-items: center;

  margin-bottom: 15px;
  background: #eaeaea;
  border-radius: 4px;
  color: #7a7a7a;
  cursor: pointer;
}
.graph_container {
  width: 100%;
  padding-bottom: 30px;
}
/* Graph Section */
/* Tips and Accuracy Section */
.tip_and_accuracy_score_section {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
  padding-bottom: 45px;
}
.tip_section {
  display: flex;
  flex-direction: column;
  width: 33%;
  font-size: 18px;
  color: #7a7a7a;
}
.tip_container {
  display: flex;
  align-items: center;
  width: 295px;
  padding-bottom: 15px;
  border-bottom: 1px solid #eaeaea;
}
.tip_container > img {
  width: 22px;
  height: 22px;
  margin-right: 15px;
}
.tip_description {
  width: 295px;
  padding-top: 24px;
  font-weight: 300;
  line-height: 21px;
}
.accuracy_section {
  width: 295px;
  font-size: 18px;
  color: #7a7a7a;
}
.accuracy_percentage {
  padding: 24px 0 30px 0;
  font-weight: 500;
  font-size: 36px;
  color: #11256d;
  line-height: 1;
}
.push_to_platform_btn {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  background: #11256d;
  color: #fff;
  font-size: 15px;
  border-radius: 4px;
  cursor: pointer;
}
/* Tips and Accuracy Section */

/* Detailed Report Section */
.detailed_report_section {
  border-top: 1px solid #eaeaea;
}
.detailed_report_heading {
  display: flex;
  align-items: center;
  padding: 22px 0;
  border-bottom: 1px solid #eaeaea;
}
.detailed_report_heading > div {
  margin-right: 15px;
  font-size: 24px;
  font-weight: 500;
}
.white_bottom_bar {
  width: 100%;
  height: 40px;
  bottom: 0;
  background-color: #fff;
}
.details_table {
  width: 100%;
  font-weight: 400;
}
.details_table  tr {
  display: flex;
  justify-content: space-between;
}
.details_table  tr:first-child {
  padding: 13px 0;
  border-bottom: 1px solid #eaeaea;
}
.details_table  tr:not(:first-child) {
  padding: 22px 0;
  border-bottom: 1px solid #eaeaea;
}
.details_table  tr > th {
  font-weight: 400;
  color: #7a7a7a;
}
.details_table  tr > td:first-child {
  font-weight: 500;
  color: #2c2c2c;
}
.details_table  tr  td:not(:first-child) {
  color: #9a9a9a;
  text-align: center;
}

.details_table  tr  th:first-child,
td:first-child {
  width: 290px;
  padding-right: 30px;
}
.details_table  tr  th:nth-child(2),
td:nth-child(2) {
  width: 8vw;
}

.details_table  tr  th:nth-child(3),
td:nth-child(3) {
  width: 9vw;
}

.details_table  tr th:nth-child(4),
td:nth-child(4) {
  width: 9vw;
}

.details_table > tr > th:nth-child(5),
td:nth-child(5) {
  width: 9vw;
}

.details_table > tr > th:nth-child(6),
td:nth-child(6) {
  width: 9vw;
}
.details_table > tr > td {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.graph {
  display: flex;
  width: 100%;
  background-color: #fff;
  height: 100px;
}

.graph_bottom {
  display: flex;
  width: 100%;
}
