.ant-modal-content {
    border-radius: 0.6vw;
}
.ant-modal-header {
    border: none;
    padding: 3vh 3vw 2vh 1vw;
}
.ant-modal-header .ant-modal-title {
    font-size: 1.2vw;
}
.ant-modal-body {
    padding: 0 1vw 0 1vw;
}
.ant-modal-body p {
    font-size: 1.1vw;
}
.ant-modal-footer {
    border: none;
    padding: 3vh 1vw 3vh 1vw;
}