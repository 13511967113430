.ant-progress-icon{
    width:2vw;
}

.ant-progress-bg{
    height: 0.6vw !important;
}

.ant-progress-text{
    font-size: 1vw;
}

.ant-progress-outer{
    width: 95%;
}

.anticon-check-circle{
    font-size: 1.2vw !important;
}