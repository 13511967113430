.training_section {
  display: flex;
  align-items: center;
  padding: 15px 0;
  border-bottom: 1px solid #eaeaea;
  margin-top: -20px;
}
.training_section > img {
  margin-right: 15px;
}
.training_section > div:first-of-type {
  color: #7a7a7a;
  font-size: 18px;
}
.training_percentage {
  color: #11256d;
  font-weight: 500;
  font-size: 18px;
  margin-left: auto;
}
/* Data Section */
.data_section {
  display: flex;
  align-items: center;
  padding: 20px 0 16px 0;
  border-bottom: 1px solid #eaeaea;
}
.data_container {
  display: flex;
  flex-direction: column;
  width: 25%;
}
.data_heading {
  font-size: 15px;
  color: #c4c4c4;
}
.data_value {
  font-weight: 500;
  font-size: 18px;
}
/* Data Section */

/* Graph Section */
.graph_section {
  display: flex;
  padding: 37px 0 72px 0;
}
.graph_section_1 {
  width: 33%;
  align-self: flex-end;
  display: flex;
  flex-direction: column;
}
.graph_section_1_heading {
  width: 295px;
  font-size: 18px;
  color: #7a7a7a;
  padding-bottom: 14px;
  border-bottom: 1px solid #eaeaea;
}
.stage_section {
  display: flex;
  align-items: center;
  width: 295px;
  padding: 20px 0;
  font-size: 15px;
  color: #c4c4c4;
  border-bottom: 1px solid #eaeaea;
}
.stage2_section {
  display: flex;
  align-items: center;
  width: 295px;
  padding: 20px 0;
  font-size: 15px;
  color: #c4c4c4;

}
.stage_section_circle_1 {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: rgba(27, 169, 76, 1);
  margin-right: 15px;
}
.stage_section_circle_2 {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: rgba(27, 169, 76, 0.7);
  margin-right: 15px;
}
.stage_section_circle_3 {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: rgba(27, 169, 76, 0.4);
  margin-right: 15px;
}
.stage_percentage {
  font-size: 18x;
  font-weight: 500;
  margin-left: auto;
  color: #2c2c2c;
}

.graph_section_2 {
  width: 33%;
}
.graph_section_2 > img {
  width: 360px;
  height: 360px;
}
.graph_section_3 {
  align-self: flex-end;
  display: flex;
  flex-direction: column;
  width: 33%;
  margin-left: auto;
}
.tip_container {
  display: flex;
  align-items: center;
  width: 295px;
  margin-left: auto;
  padding-bottom: 15px;
  font-size: 18px;
  color: #7a7a7a;
  border-bottom: 1px solid #eaeaea;
}
.tip_container > img {
  width: 22px;
  height: 22px;
  margin-right: 15px;
}
.tip_description {
  width: 295px;
  padding-top: 24px;
  margin-left: auto;
  font-weight: 300;
  font-size: 18px;
  color: #7a7a7a;
}
/* Graph Section */
.white_bottom_bar {
  width: 100%;
  height: 40px;
  bottom: 0;
  background-color: #fff;
}
