.svg-center > svg{
    width: 1.2vw;
    height: 1.2vw;
}

.ant-modal-header .ant-modal-title{
    font-size: 1.4vw;
}


.ant-modal-body{
    background-color: #FAFAFA;
}

.ant-modal-header{
    background-color: #FAFAFA;

}

.ant-modal-footer{
    background-color: #FAFAFA;
    display:none

}

.ant-select-selector{
    background-color: #e1e1e1 !important;
}

.ant-select-selection-placeholder{
    color:black
}