@import "antd/dist/antd.css";
/* @import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap"); */

/* @import url("https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@300&display=swap"); */
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700;900&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Barlow:wght@700");


* {
    font-family: "Roboto", sans-serif;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

.ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
    background: #11256D;
}
.ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-tail::after {
  background-color: red !important;
}

.custom_scroll {
    overflow-y: auto;
 scrollbar-color: #11256d white;
  scrollbar-width: thin;
}
*{
    
    scrollbar-color: #11256d white;
  scrollbar-width: thin;

}
html body {
    width: 100% !important;
}
.custom_scroll-x {
    overflow-x: auto;
}

.custom_scroll::-webkit-scrollbar {
    width: 3px;
    height: 3px;
}
.custom_scroll::-webkit-scrollbar-track {
    background-color: #fafafafa;
}
.custom_scroll::-webkit-scrollbar-thumb {
    background-color: #11256d;
}

.custom_scroll::-webkit-scrollbar-thumb:hover {
    background-color: #294cca;
}
.custom_scroll-x::-webkit-scrollbar {
    height: 2px;
}
.custom_scroll-x::-webkit-scrollbar-track {
    background-color: #fafafafa;
}
.custom_scroll-x::-webkit-scrollbar-thumb {
    background-color: #11256d;
}

.custom_scroll-x::-webkit-scrollbar-thumb:hover {
    background-color: #294cca;
}

.ant-slider-handle{
    background-color:#11256d;
    border-color:#11256d;
}

.ant-slider-handle:hover{
    background-color:#11256d;
    border-color:#11256d;
}


.ant-slider-track{
    background-color:#11256d;
}
.ant-slider-track:hover{
    background-color:#11256d;
}

.ant-slider-dot-active{
    background-color:#11256d;
    border-color: #11256d;

}

.ant-pagination-prev .ant-pagination-item-link, .ant-pagination-next .ant-pagination-item-link {
    display: flex;
    justify-content: center;
    align-items: center;
}

.ant-pagination-prev .ant-pagination-item-link, .ant-pagination-prev .ant-pagination-item-link {
    display: flex;
    justify-content: center;
    align-items: center;
}

.ant-checkbox-checked .ant-checkbox-inner {
    background-color: #11256D;
    border-color: #11256D;
  }

  .ant-checkbox-disabled .ant-checkbox-inner {
    background-color: #c4c4c4;
    border-color: #c4c4c4;
  }
  .ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner::after {
    border-color: white;
}



input{
    accent-color:#11256d ;
}


.ant-pagination-item{
    border:none;
}
.ant-pagination-prev .ant-pagination-item-link, .ant-pagination-next .ant-pagination-item-link{
    border:none;
}

.ant-pagination-item-active{
    background-color: #11256D;
    color:white !important;
}

.ant-pagination-item-active a{
    color:white !important;
}

.ant-pagination-item-active:hover{
    color:white !important;
}
.ant-pagination-item-active a:hover{
    color:white !important;
}
